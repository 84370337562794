@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700&display=swap');


/* Define animations */
@keyframes fadeInTop {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .about {
      width: 100%;
      height: 1435px;
      margin-top: 1%;
  }
  
  .about-txt h1 {
      font-family: Berkshire Swash;
      font-size: 64px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: white;
      opacity: 0;
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 0.2s;
  }
  
  .about-txt p {
      margin-top: 2%;
      font-family: Montserrat Alternates;
      font-size: 40px;
      margin-left: 1%;
      font-weight: 700;
      line-height: 57px;
      color: white;
      text-align: center;
      opacity: 0;
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 0.4s;
  }
  
  .about-txt span {
      font-family: Montserrat Alternates;
      font-size: 40px;
      color: rgba(30, 210, 221, 1);
      opacity: 0;
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 0.6s;
  }
  
  .left-img {
      display: flex;
      margin-top: 2%;
      opacity: 0;
      animation: slideInLeft 1s ease forwards;
      animation-delay: 0.8s;
  }
  
  .left-img img.img-3 {
      margin-left: 3%;
      width: 750px;
      height: 600px;
  }
  
  .left-img p {
      margin-top: -2%;
      margin-left: 2%;
      margin-right: 5%;
      font-family: Poppins;
      font-size: 35px;
      font-weight: 400;
      line-height: 60px;
      text-align: justify;
      color: white;
      opacity: 0;
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 1s;
  }
  
  section.about img.leaf {
      width: 200px;
      height: 200px;
      margin-left: 87%;
      margin-top: -8%;
      opacity: 0;
      animation: slideInRight 1s ease forwards;
      animation-delay: 1.2s;
  }
  
  img.leaf-right {
      width: 200px;
      height: 200px;
      margin-top: -7%;
      opacity: 0;
      animation: slideInLeft 1s ease forwards;
      animation-delay: 1.4s;
  }
  
  .right-img {
      display: flex;
      margin-top: 2%;
      opacity: 0;
      animation: slideInRight 1s ease forwards;
      animation-delay: 1.6s;
  }
  
  .right-img .img-4 {
      margin-right: 4%;
      margin-top: -7%;
      width: 750px;
      height: 600px;
  }
   
  .right-img p {
      margin-top: -5%;
      margin-left: 3%;
      margin-right: 4%;
      font-family: Poppins;
      font-size: 35px;
      font-weight: 400;
      line-height: 60px;
      text-align: justify;
      color: white;
      opacity: 0;
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 1.8s;
  }
  
  @media screen and (min-width: 769px){
    section.about img.leaf{
        margin-left: 86%;
    }
    section.about img.leaf-right {
        margin-top: -15%;
    }
    .right-img .img-4 {
        margin-top: -7%;
    }
    .right-img p {
        margin-top: -5%;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px){
    div.about-txt p{
        font-size: 30px;
        margin-left: 0%;
    }
    div.about-txt span{
        font-size: 30px;
    }
    section.about img.leaf{
        margin-top: -28%;
        margin-left: 83%;
    }
    div.left-img p {
        font-size: 25px;
    }
    .right-img .img-4 {
        margin-top: 0%;
    }
    div.right-img p {
        font-size: 25px;
        margin-top: -2%;
    }
  }


  @media screen and (min-width: 1339px) and (max-width: 1340px){
    .right-img .img-4 {
        margin-top: 2%;
    }
    div.right-img p {
        margin-top: 2%;
    }
  }



@media screen and (max-width: 768px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 20px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 350px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 15px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 2%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 15px;
        line-height: 35px;
    }
}

@media screen and (min-width: 400px) and (max-width: 767px) {
    .about{
        height: 600px;
    }
    .about-txt h1{
        font-size: 25px;
        letter-spacing: 1px;
    }
    .about-txt p{
        font-size: 12px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 12px;
    }
    section.about img.leaf{
        width: 80px;
        height: 80px;
        margin-left: 81%;
        margin-top: -8%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 200px;
        height: 200px;
    }
    .left-img p{
        font-size: 8px;
        line-height: 24px;
    }
    img.leaf-right{
        width: 80px;
        height: 80px;
        margin-top: -15%;
    }
    .right-img{
        display: flex;
        margin-top: 2%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 200px;
        height: 200px;
    }
    .right-img p{
        font-size: 8px;
        line-height: 24px;
    }
}


@media screen and (min-width: 500px) and (max-width: 650px) {
    .about{
        height: 660px;
    }
    .about-txt h1{
        font-size: 30px;
    }
    .about-txt p{
        font-size: 15px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 15px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 77%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 250px;
        height: 250px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 12px;
        line-height: 25px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 2%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 250px;
        height: 250px;
    }
    .right-img p{
        font-size: 12px;
        line-height: 25px;
    }
}



@media screen and (min-width: 769px) and (max-width: 890px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 20px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 350px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 15px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 2%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 15px;
        line-height: 35px;
    }
}


@media screen and (min-width: 310px) and (max-width: 320px){
    .about-txt{
        width: 200px;
    }
    .right-img img.img-4{
        width: 170px;
    }
    .right-img p{
        margin-top: -10%;
    }
}

@media screen and (min-width: 359px) and (max-width: 360px){}


@media screen and (max-width: 400px) {
    .about{
        width: 100%;
        height: 610px;
    }
    .about-txt h1{
        margin-left: 10%;
        font-size: 25px;
        width: 300px;
        letter-spacing: 1px;
    }
    .about-txt p{
        margin-top: -5%;
        width: 300px;
        font-size: 9px;
        margin-left: 10%;
    }
    .about-txt span{
        font-size: 9px;
    }
    section.about img.leaf{
        width: 50px;
        height: 50px;
        margin-left: 78%;
        margin-top: -8%;
    }
    .left-img{
        width: 100%;
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 180px;
        height: 200px;
    }
    .left-img p{
        width: 175px;
        font-size: 9px;
        line-height: 15px;
    }
    img.leaf-right{
        width: 50px;
        height: 50px;
        margin-top: -15%;
    }
    .right-img{
        display: flex;
        margin-top: 0%;
        margin-left: 1%;
    }
    .right-img .img-4{
        margin-right: 5%;
        width: 180px;
        height: 200px;
    }
    .right-img p{
        font-size: 10px;
        line-height: 15px;
    }
}

@media screen and (min-width: 981px) and (max-width: 1023px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 25px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 300px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 18px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 7%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 18px;
        line-height: 35px;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 25px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 400px;
        height: 350px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 18px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 2%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 18px;
        line-height: 35px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 25px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 300px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 18px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 7%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 18px;
        line-height: 35px;
    }
}





@media screen and (min-width: 890px) and (max-width: 910px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 25px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 300px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 18px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 7%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 18px;
        line-height: 35px;
    }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 25px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 300px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 18px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 7%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 18px;
        line-height: 35px;
    }
}

@media screen and (min-width: 970px) and (max-width: 980px) {
    .about{
        height: 825px;
    }
    .about-txt h1{
        font-size: 40px;
    }
    .about-txt p{
        font-size: 25px;
        margin-left: 0%;
    }
    .about-txt span{
        font-size: 20px;
    }
    section.about img.leaf{
        width: 120px;
        height:120px;
        margin-left: 84%;
        margin-top: -15%;
    }
    .left-img{
        display: flex;
        margin-top: 3%;
    }
    div.left-img img.img-3{
        width: 300px;
        height: 300px;
    }
    .left-img p{
        margin-top: 0%;
        font-size: 18px;
        line-height: 35px;
    }
    img.leaf-right{
        width: 120px;
        height: 120px;
        margin-top: -8%;
    }
    .right-img{
        display: flex;
        margin-top: 7%;
        margin-left: 1%;
    }
    .right-img .img-4{
        width: 300px;
        height: 300px;
    }
    .right-img p{
        font-size: 18px;
        line-height: 35px;
    }
}