@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700&display=swap');


/* Define animations */
@keyframes fadeInTop {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Offer Section Styling */
  .offer {
      background-color: white;
      width: 100%;
      margin-top: 2%;
  }
  
  /* Heading Animation */
  .offer-txt h1 {
    background-color: white;
      margin-top: 4%;
      font-family: Berkshire Swash;
      font-size: 64px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: rgba(27, 70, 70, 1);
      opacity: 0; /* Initially hidden */
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 0.2s; /* Delay for h1 */
  }
  
  /* Paragraph Container */
  .para {
      margin-top: 2%;
      margin-left: 3%;
      margin-right: 3%;
  }
  
  /* Paragraph Text Animation */
  .para p {
      color: rgba(27, 70, 70, 1);
      font-family: Poppins;
      font-size: 40px;
      line-height: 70px;
      text-align: justify;
      opacity: 0; /* Initially hidden */
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 0.4s; /* Delay for first paragraph */
  }
  
  .para span {
      color: rgba(27, 70, 70, 1);
      font-family: Poppins;
      font-size: 40px;
      font-weight: 700;
      line-height: 70px;
      text-align: justify;
      opacity: 0; /* Initially hidden */
      animation: fadeInTop 0.8s ease forwards;
      animation-delay: 0.6s; /* Delay for span */
  }
  
  /* Set Initial State */
  .offer-txt h1,
  .para p,
  .para span {
      opacity: 0; /* Initially hidden */
      transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  }

  

@media screen and (max-width: 768px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 20px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 20px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}


@media screen and (min-width: 769px) and (max-width: 890px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 20px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}

@media screen and (min-width: 400px) and (max-width: 767px) {
    .offer-txt h1{
        font-size: 25px;
        letter-spacing: 1px;
    }
    .para p{
        font-size: 12px;
        line-height: 35px;
    }
    .para span{
        font-size: 14px;
        line-height: 35px;
    }
}

@media screen and (max-width: 400px) {
    .offer{
        margin-top: -24%;
        width: 100%;
    }
    .offer-txt h1{
        font-size: 25px;
        letter-spacing: 1px;
    }
    .para p{
        font-size: 12px;
        line-height: 35px;
    }
    .para span{
        font-size: 14px;
        line-height: 35px;
    }
}

@media screen and (min-width: 310px) and (max-width: 320px){
    .offer{
        margin-top: 5%;
        width: 100%;
    }
}

@media screen and (min-width: 981px) and (max-width: 1023px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 25px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}



@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 25px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 25px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}




@media screen and (min-width: 970px) and (max-width: 980px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 25px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
    .offer-txt h1{
        font-size: 40px;
    }
    .para p{
        font-size: 25px;
        line-height: 35px;
    }
    .para span{
        font-size: 22px;
        line-height: 35px;
    }
}