@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');


/* Define animations */
@keyframes slideInBottom {
    from {
      opacity: 0;
      transform: translateY(20px); /* Start from below */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* End at original position */
    }
  }
  
  /* Footer Container Styles */
  .footerContainer {
      width: 100%;
      padding: 20px;
      text-align: center;
      background-color: #f0f0f0;
      font-family: Arial, sans-serif;
      color: #333;
  }
  
  .heading {
      font-family: Berkshire Swash;
      font-size: 64px;
      font-weight: 700;
      line-height: 79.5px;
      text-align: center;
      color: rgba(27, 70, 70, 1);
      opacity: 0; /* Initially hidden */
      animation: slideInBottom 0.8s ease forwards; /* Animation for heading */
      animation-delay: 0.2s; /* Delay for heading */
  }
  
  .subheading {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 400;
      line-height: 60px;
      text-align: center;
      opacity: 0; /* Initially hidden */
      animation: slideInBottom 0.8s ease forwards; /* Animation for subheading */
      animation-delay: 0.4s; /* Delay for subheading */
  }
  
  .contentContainer {
      margin-top: 3%;
      display: flex;
      flex-wrap: wrap;
  }
  
  /* Map and Address Styles */
  .mapAndAddress {
      display: flex;
      flex: 1;
      max-width: 20%;
      padding: 10px;
      margin-left: 10%;
  }
  
  iframe {
      height: 250px;
      border-radius: 30px;
  }
  
  .address {
      width: 500px;
      margin-top: 1%;
      margin-left: 2%;
      text-align: left;
      color: #2c3e50;
      opacity: 0; /* Initially hidden */
      animation: slideInBottom 0.8s ease forwards; /* Animation for address */
      animation-delay: 0.6s; /* Delay for address */
  }
  
  .boldText {
      font-family: Poppins;
      font-size: 25px;
      font-weight: 600;
      line-height: 30px;
  }
  
  .address p {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      text-align: left;
  }
  
  /* Contact and Social Styles */
  .contactAndSocial {
      flex: 1;
      max-width: 400px;
      padding: 10px;
      text-align: left;
  }
  
  .contact {
      font-family: Poppins;
      font-size: 21px;
      font-weight: 400;
      line-height: 51px;
      margin-bottom: 15px;
      color: #2c3e50;
      opacity: 0; /* Initially hidden */
      animation: slideInBottom 0.8s ease forwards; /* Animation for contact */
      animation-delay: 0.8s; /* Delay for contact */
  }
  div.contact p a.email{
    color: #2c3e50;
    position: relative;
    top: -4px;
  }
  .socialMedia {
      font-family: Poppins;
      font-size: 21px;
      font-weight: 400;
      line-height: 51px;
      color: #2c3e50;
      opacity: 0; /* Initially hidden */
      animation: slideInBottom 0.8s ease forwards; /* Animation for social media */
      animation-delay: 1s; /* Delay for social media */
  }
  .socialMedia a p{
    color: #2c3e50;
  }
  p.email{
    color:#2c3e50 ;
    position: relative;
    top: 4%;
  }


@media screen and (min-width: 769px) {
    div.address{
        margin-left: 6%;
    }
}


@media screen and (min-width: 767px) and (max-width: 768px) {
    .heading {
        font-size: 40px;
    }
    .subheading {
        font-size: 30px;
    }
    .mapAndAddress {
        margin-left: 8%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    iframe{
        width: 250px;
    }
    .address {
        margin-top: -35%;
        margin-left: 54%;
    }
    .boldText {
        font-size: 20px;
    }
    .address p{
        font-size: 15px;
    }
    .contact {
        margin-left: 16%;
        font-size: 15px;
        line-height: 25px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -18%;
        margin-left: 100%;
        font-size: 15px;
    }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
    .heading {
        font-size: 40px;
    }
    .subheading {
        font-size: 30px;
    }
    div.mapAndAddress {
        margin-left: 25%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    iframe{
        width: 250px;
    }
    div.address {
        margin-top: 0%;
        margin-left: 15%;
    }
    .boldText {
        font-size: 20px;
    }
    .address p{
        font-size: 15px;
    }
    div.contact {
        width: 250px;
        margin-top: -43%;
        margin-left: 70%;
        font-size: 12px;
        line-height: 25px;
    }
    div.socialMedia {
        line-height: 25px;
        width: 250px;
        margin-top: 5%;
        margin-left: 70%;
        font-size: 12px;
    }
}

@media screen and (min-width: 769px) and (max-width: 890px) {
    .heading {
        font-size: 40px;
    }
    .subheading {
        font-size: 30px;
    }
    .mapAndAddress {
        margin-left: 8%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    iframe{
        width: 250px;
    }
    div.address {
        margin-top: -35%;
        margin-left: 52%;
    }
    .boldText {
        font-size: 20px;
    }
    .address p{
        font-size: 15px;
    }
    .contact {
        margin-left: 16%;
        font-size: 15px;
        line-height: 25px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -18%;
        margin-left: 100%;
        font-size: 15px;
    }
}




@media screen and (max-width: 400px) {
    .footerContainer {
        width: 100%;
    }
    .heading {
        font-size: 25px;
        letter-spacing: 1px;
    }
    .dot{
        margin-top: -7%;
        font-size: 20px;
    }
    .subheading {
        font-size: 18px;
    }
    .mapAndAddress {
        margin-left: 10%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    iframe{
        width: 250px;
    }
    .address {
        margin-top: 5%;
        margin-left: 3%;
    }
    .boldText {
        font-size: 15px;
    }
    .address p{
        line-height: 20px;
        font-size: 10px;
    }
    .contact {
        margin-top: 5%;
        margin-left: 0%;
        font-size: 9px;
        line-height: 25px;
    }
    .socialMedia {
        line-height: 25px;
        width: 100px;
        margin-top: -23%;
        margin-left: 65%;
        font-size: 10px;
    }
}



@media screen and (min-width: 400px) and (max-width: 767px) {
    .heading {
        font-size: 25px;
        letter-spacing: 1px;
    }
    .dot{
        margin-top: -7%;
        font-size: 20px;
    }
    .subheading {
        font-size: 18px;
    }
    .mapAndAddress {
        margin-left: 15%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    iframe{
        width: 250px;
    }
    .address {
        margin-top: 5%;
        margin-left: 0%;
    }
    .boldText {
        font-size: 15px;
    }
    .address p{
        line-height: 20px;
        font-size: 10px;
    }
    .contact {
        margin-top: -45%;
        margin-left: 50%;
        font-size: 10px;
        line-height: 25px;
    }
    .socialMedia {
        line-height: 25px;
        width: 100px;
        margin-top: 5%;
        margin-left: 50%;
        font-size: 10px;
    }
}

@media screen and (min-width: 981px) and (max-width: 1023px) {
    div.address{
        margin-left: 15%;
    }
    div.contentContainer{
        margin-left: 3%;
    }
    div.contact {
        margin-left: 30%;
        width: 500px;
        font-size: 18px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -28%;
        margin-left: 120%;
        font-size: 18px;
    }
}



@media screen and (min-width: 1023px) and (max-width: 1025px) {
    div.address{
        margin-left: 15%;
    }
    div.contact {
        margin-left: 30%;
        width: 500px;
        font-size: 18px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -28%;
        margin-left: 120%;
        font-size: 18px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    div.address{
        margin-left: 15%;
    }
    div.contentContainer{
        margin-left: 3%;
    }
    div.contact {
        margin-left: 30%;
        width: 500px;
        font-size: 18px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -28%;
        margin-left: 120%;
        font-size: 18px;
    }
}

@media screen and (min-width: 890px) and (max-width: 910px) {
    div.address{
        margin-left: 15%;
    }
    div.contact {
        margin-left: 30%;
        width: 500px;
        font-size: 18px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -28%;
        margin-left: 120%;
        font-size: 18px;
    }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
    div.address{
        width: 300px;
        margin-left: 20%;
    }
    div.contactAndSocial{
        margin-left: 10%;
    }
}

@media screen and (min-width: 970px) and (max-width: 980px) {
    div.address{
        margin-left: 15%;
    }
    div.contentContainer{
        margin-left: 3%;
    }
    div.contact {
        margin-left: 30%;
        width: 500px;
        font-size: 18px;
    }
    .socialMedia {
        line-height: 25px;
        width: 300px;
        margin-top: -28%;
        margin-left: 120%;
        font-size: 18px;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1390px){
    div.address{
        width: 300px;
        margin-left: 8%;
    }
}

@media screen and (min-width: 1390px) and (max-width: 1400px){
    div.contentContainer{
        margin-left: -5%;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1439px){
    div.contentContainer{
        margin-left: -5%;
    }
}









.bottom-footer{
    width: 100%;
    background: rgba(1, 77, 78, 1);
    display: flex;
}
.bottom-footer p{
    margin-left: 5%;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 57px;
    text-align: center;
    color: white;
}
.bottom-footer span{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 57px;
    text-align: center;
}
.privacy{
    width: 200px;
    margin-left: 15%;
}
.privacy p{
    margin-top: 8%;
    font-size: 22px;
    line-height: 25px;
    text-decoration: underline;
}


@media screen and (min-width: 767px) and (max-width: 768px) {
    div.bottom-footer p{
        margin-left: 5%;
        font-size: 10px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 10px;
    }
    .privacy p{
        margin-top: 15%;
    }
}

@media screen and (min-width: 400px) and (max-width: 767px) {
    div.bottom-footer p{
        margin-left: 5%;
        font-size: 2px;
        letter-spacing: 0.8px;
    }
    .bottom-footer span{
        font-size: 2px;
    }
    .privacy p{
        margin-top: 8%;
    }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
    div.bottom-footer{
        width: 100%;
    }
    div.bottom-footer p{
        margin-left: 12%;
        font-size: 6px;
    }
    div.bottom-footer p span{
        font-size: 5px;
    }
}



@media screen and (max-width: 400px) {
    div.bottom-footer{
        width: 320px;
    }
    .bottom-footer p{
        font-size: 4px;
        letter-spacing: 0.6px;
    }
    .bottom-footer span{
        font-size: 4px;
    }
    div.privacy{
        margin-left: -30%;
    }
    .privacy p{
        margin-left: 70%;
        margin-top: 7%;
        font-size: 6px;
    }
}

@media screen and (min-width: 981px) and (max-width: 1023px) {
    .bottom-footer p{
        margin-left:10%;
        font-size: 12px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 12px;
    }
    .privacy p{
        margin-top: 15%;
    }
}


@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .bottom-footer p{
        margin-left:10%;
        font-size: 12px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 15px;
    }
    .privacy p{
        margin-top: 15%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .bottom-footer p{
        margin-left:10%;
        font-size: 12px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 12px;
    }
    .privacy p{
        margin-top: 15%;
    }
}

@media screen and (min-width: 890px) and (max-width: 910px) {
    .bottom-footer p{
        margin-left:10%;
        font-size: 12px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 15px;
    }
    .privacy p{
        margin-top: 15%;
    }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
    .bottom-footer p{
        margin-left:10%;
        font-size: 12px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 12px;
    }
    .privacy p{
        margin-top: 15%;
    }
}


@media screen and (min-width: 970px) and (max-width: 980px) {
    .bottom-footer p{
        margin-left:10%;
        font-size: 12px;
        line-height: 0.5px;
    }
    .bottom-footer span{
        font-size: 12px;
    }
    .privacy p{
        margin-top: 15%;
    }
}