@keyframes slideInUp {
    from {
      opacity: 0; /* Start fully transparent */
      transform: translateY(20px); /* Start slightly below */
    }
    to {
      opacity: 1; /* End fully visible */
      transform: translateY(0); /* End in the original position */
    }
  }
  


.home-img{
    width: 119%;
    margin-top: 0.2%;
    margin-left: -10%;
    opacity: 0; /* Initial state */
    animation: slideInUp 0.8s forwards; /* Animation for slide-in */
    transition: opacity 0.5s ease; /* Smooth transition */
}

@media screen and (max-width: 768px) {
    img.home-img{
        width: 119%;
        height: 300px;
        margin-top: 0.5%;
        margin-left: -10%;
    }
}

@media screen and (max-width: 400px) {
    img.home-img{
        width: 119%;
        height: 200px;
        margin-top: 1%;
        margin-left: -10%;
    }
}

@media screen and (min-width: 400px) and (max-width: 767px){
    img.home-img{
        width: 119%;
        height: 180px;
        margin-top: 1%;
        margin-left: -10%;
    }
}