@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');


/* Define animations */
@keyframes slideInBottom {
    from {
      opacity: 0;
      transform: translateY(20px); /* Starts from below */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInTop {
    from {
      opacity: 0;
      transform: translateY(-20px); /* Starts from above */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

    /* Cuisine Section Styling */

  .cuisine {
    padding: 50px;
    text-align: center;
  }
  
  .cuisine-txt h1 {
      font-family: Berkshire Swash;
      font-size: 64px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: white;
  }
  
  .slider-container {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  div.img-container {
    margin-top: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .slide-img {
    width: 300px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    text-align: left;
    margin-left: 15%;
  }
  
  .text-content p {
    font-family: Poppins;
    margin-left: 15%;
    color: white;
    width: 320px;
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    text-align: justify;
  }
  button.slick-arrow.slick-prev::before{
    font-size: 40px;
  }
  button.slick-arrow.slick-next::before{
    font-size: 40px;
  }
  ul.slick-dots li button::before{
    font-size: 15px;
    color: white;
    margin-top: -5%;
  }
  .redback{
    margin-left: -4%;
    width: 110%;
    height: 650px;
    margin-top: -45%;
    background-color: red;
  }
  
  @media screen and (min-width: 500px) and (max-width: 650px) {
    .cuisine-txt h1 {
      font-family: Berkshire Swash;
      font-size: 40px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: white;
  }
  
  .slider-container {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .slide {
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .slide-img {
    margin-left: -45%;
    width: 200px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    margin: 10px 0;
  }
  
  .text-content p {
    width: 180px;
    margin-left: -1%;
    font-family: Poppins;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: justified;
  }
  button.slick-arrow.slick-prev::before{
    font-size: 30px;
  }
  button.slick-arrow.slick-next::before{
    font-size: 30px;
    margin-left: 40%;
  }
  ul.slick-dots li button::before{
    margin-top: -99%;
    font-size: 8px;
  }
  div.redback{
    width: 115%;
    margin-left: -7.5%;
    margin-top: -45%;
    height: 250px;
  }
  }

@media screen and (min-width: 767px) and (max-width: 768px) {
  .cuisine-txt h1 {
    font-family: Berkshire Swash;
    font-size: 40px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    color: white;
}

.slider-container {
  max-width: 100%;
  margin: 0 auto;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.slide-img {
  width: 200px;
  height: auto;
}
.text-content h3 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  line-height: 60px;
  color: white;
  margin: 10px 0;
}

.text-content p {
  width: 180px;
  margin-left: -1%;
  font-family: Poppins;
  color: white;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: justified;
}
button.slick-arrow.slick-prev::before{
  font-size: 30px;
}
button.slick-arrow.slick-next::before{
  font-size: 30px;
  margin-left: 40%;
}
ul.slick-dots li button::before{
  margin-top: -99%;
  font-size: 8px;
}
.redback{
  width: 115%;
  margin-left: -7.5%;
  margin-top: -67%;
  height: 450px;
}
}

@media screen and (min-width: 769px) and (max-width: 890px) {
  .cuisine-txt h1 {
    font-family: Berkshire Swash;
    font-size: 40px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    color: white;
}

.slider-container {
  max-width: 100%;
  margin: 0 auto;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.slide-img {
  width: 200px;
  height: auto;
}
.text-content h3 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  line-height: 60px;
  color: white;
  margin: 10px 0;
}

.text-content p {
  width: 180px;
  margin-left: -1%;
  font-family: Poppins;
  color: white;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: justified;
}
button.slick-arrow.slick-prev::before{
  font-size: 30px;
}
button.slick-arrow.slick-next::before{
  font-size: 30px;
  margin-left: 40%;
}
ul.slick-dots li button::before{
  margin-top: -99%;
  font-size: 8px;
}
.redback{
  width: 115%;
  margin-left: -7.5%;
  margin-top: -67%;
  height: 450px;
}
}




@media screen and (min-width: 400px) and (max-width: 767px) {
    .cuisine-txt h1 {
        margin-top: -10%;
        font-family: Berkshire Swash;
        font-size: 30px;
        font-weight: 600;
        line-height: 64px;
        text-align: center;
        color: white;
    }
    
    .slider-container {
      max-width: 100%;
      margin: 0 auto;
    }
    
    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
    
    .img-container {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
    }
    
    .slide-img {
      width: 95px;
      height: auto;
    }
    .text-content h3 {
      font-family: Poppins;
      font-size: 8px;
      color: white;
      text-align: left;
      line-height: 12px;
      margin-left: -15%;
    }
    
    .text-content p {
      width: 85px;
      margin-top: 5%;
      font-family: Poppins;
      line-height: 12px;
      color: white;
      text-align: justify;
      font-size: 0.38rem;
      margin-left: -15%;
    }
    button.slick-arrow.slick-prev::before{
      font-size: 25px;
    }
    button.slick-arrow.slick-next::before{
      font-size: 25px;
      margin-left: 40%;
    }
    ul.slick-dots li button::before{
      margin-top: 90%;
      font-size: 8px;
  }
    .redback{
      margin-left: -14.8%;
      width: 130%;
      margin-top: -60%;
      height: 240px;
    }
}

@media screen and (max-width: 400px) {
    .cuisine{
      margin-top: -10%;
        width: 100%;
    }
    .cuisine-txt h1 {
        font-family: Berkshire Swash;
        font-size: 30px;
        font-weight: 600;
        line-height: 64px;
        text-align: center;
        color: white;
    }
    .slider-container {
      max-width: 100%;
      margin: 0 auto;
    }
    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
    .img-container {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
    }
    .slide-img {
      width: 85px;
      gap: 10px;
      height: auto;
    }
    .text-content h3 {
      font-family: Poppins;
      font-size: 8px;
      color: white;
      text-align: left;
      line-height: 12px;
      margin-left: -30%;
    }
    
    .text-content p {
      width: 75px;
      margin-top: 5%;
      font-family: Poppins;
      line-height: 12px;
      color: white;
      text-align: justify;
      font-size: 0.38rem;
      margin-left: -30%;
    }
    button.slick-arrow.slick-prev::before{
      font-size: 20px;
    }
    button.slick-arrow.slick-next::before{
      font-size: 20px;
      margin-left: 50%;
    }
    ul.slick-dots li button::before{
        margin-top: 25%;
        font-size: 8px;
    }
    .redback{
      margin-left: -18%;
      width: 137%;
      margin-top: -75%;
      height: 250px;
    }
}

@media screen and (min-width: 310px) and (max-width: 320px){
  .slide-img {
    width: 70px;
  }
  .text-content h3 {
    font-family: Poppins;
    width: 60px;
    font-size: 8px;
    color: white;
    text-align: left;
    line-height: 12px;
    margin-left: -40%;
  }
  .text-content p {
    width: 60px;
    margin-top: 5%;
    font-family: Poppins;
    line-height: 12px;
    color: white;
    text-align: justify;
    font-size: 0.38rem;
    margin-left: -40%;
  }
  button.slick-arrow.slick-prev::before{
    font-size: 20px;
  }
  button.slick-arrow.slick-next::before{
    font-size: 20px;
    margin-left: 50%;
  }
  .redback{
    margin-left: -22.3%;
    width: 145%;
    margin-top: -110%;
    height: 250px;
  }
}

@media screen and (min-width: 981px) and (max-width: 1023px) {
  .slide-img {
    width: 280px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    margin: 10px 0;
  }
  
  .text-content p {
    font-family: Poppins;
    width: 250px;
    margin-left: 0%;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    text-align: justified;
  }
  ul.slick-dots li button::before{
    color: white;
    margin-top: -5%;
  }
  .redback{
    width: 111%;
    margin-top: -65%;
    margin-left: -5.5%;
  }
}


@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .cuisine-txt h1 {
        font-family: Berkshire Swash;
        font-size: 64px;
        font-weight: 700;
        line-height: 64px;
        text-align: center;
        color: white;
    }
    
    .slider-container {
      max-width: 100%;
      margin: 0 auto;
    }
    
    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
    
    .img-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }
    
    .slide-img {
      width: 280px;
      height: auto;
    }
    .text-content h3 {
      font-family: Poppins;
      font-size: 30px;
      font-weight: 700;
      line-height: 60px;
      color: white;
      margin: 10px 0;
    }
    
    .text-content p {
      font-family: Poppins;
      width: 250px;
      margin-left: 0%;
      color: white;
      font-size: 15px;
      font-weight: 400;
      line-height: 50px;
      text-align: left;
      text-align: justified;
    }
    ul.slick-dots li button::before{
      color: white;
      margin-top: -5%;
    }
    .redback{
      width: 111%;
      margin-top: -65%;
      margin-left: -5.5%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .slide-img {
    width: 280px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    margin: 10px 0;
  }
  
  .text-content p {
    font-family: Poppins;
    width: 250px;
    margin-left: 0%;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    text-align: justified;
  }
  ul.slick-dots li button::before{
    color: white;
    margin-top: -5%;
  }
  .redback{
    width: 111%;
    margin-top: -65%;
    margin-left: -5.5%;
  }
}


@media screen and (min-width: 1025px) and (max-width: 1440px){
  .redback{
    width: 108%;
    margin-left: -4%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  div.text-content h3 {
    margin-left: 6%;
  }
  div.text-content p {
    margin-left: 6%;
  }
}

@media screen and (min-width: 890px) and (max-width: 910px) {
  .slide-img {
    width: 280px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    margin: 10px 0;
  }
  
  .text-content p {
    font-family: Poppins;
    width: 250px;
    margin-left: 0%;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    text-align: justified;
  }
  ul.slick-dots li button::before{
    color: white;
    margin-top: -5%;
  }
  .redback{
    width: 111%;
    margin-top: -65%;
    margin-left: -5.5%;
  }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
  .slide-img {
    width: 240px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    margin: 10px 0;
  }
  
  .text-content p {
    font-family: Poppins;
    width: 250px;
    margin-left: 0%;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    text-align: justified;
  }
  ul.slick-dots li button::before{
    color: white;
    margin-top: -5%;
  }
  .redback{
    width: 111%;
    margin-top: -65%;
    margin-left: -5.5%;
  }
}




@media screen and (min-width: 970px) and (max-width: 980px) {
  .slide-img {
    width: 280px;
    height: auto;
  }
  .text-content h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: white;
    margin: 10px 0;
  }
  
  .text-content p {
    font-family: Poppins;
    width: 250px;
    margin-left: 0%;
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    text-align: justified;
  }
  ul.slick-dots li button::before{
    color: white;
    margin-top: -5%;
  }
  .redback{
    width: 111%;
    margin-top: -65%;
    margin-left: -5.5%;
  }
}