.animated-section {
    opacity: 0; /* Start as invisible */
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Smooth transition */
  }
  
  .animated-section.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Move to original position */
  }
  