@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700&display=swap');


/* Define animations */
@keyframes slideInBottom {
    from {
      opacity: 0;
      transform: translateY(20px); /* Starts from below */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInTop {
    from {
      opacity: 0;
      transform: translateY(-20px); /* Starts from above */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Partner Section Styling */
  .partner {
      width: 100%;
      margin-top: 15%;
  }
  
  .partner-txt h1 {
      margin-top: -16%;
      font-family: Berkshire Swash;
      font-size: 64px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: white;
  }
  
  /* Flex container for text and images */
  .partner .texted {
      display: flex;
  }
  
  /* Image Styles */
  .img-2 {
      width: 270px;
      height: 270px;
      margin-left: -1%;
      margin-top: 5%;
      opacity: 0; /* Initially hidden */
      animation: slideInTop 0.8s ease forwards; /* Animation for images */
      animation-delay: 0.2s; /* Delay for the first image */
  }
  
  /* Text Container Styles */
  .texted {
      margin-top: -5%;
      color: white;
      display: flex;
  }
  
  /* Text Item Styles */
  .texted .item-1,
  .texted .item-2,
  .texted .item-3,
  .texted .item-4 {
    margin-left: 2.5%;
      margin-top: 10%;
      width: 400px;
      opacity: 0; /* Initially hidden */
      animation: slideInBottom 0.8s ease forwards; /* Animation for text items */
  }
  
  /* Animation delays for each text item */
  .texted .item-1 {
      animation-delay: 0.4s; /* Delay for first text item */
  }
  
  .texted .item-2 {
      animation-delay: 0.6s; /* Delay for second text item */
  }
  
  .texted .item-3 {
      animation-delay: 0.8s; /* Delay for third text item */
  }
  
  .texted .item-4 {
      animation-delay: 1s; /* Delay for fourth text item */
  }
  
  /* Headings and Paragraphs */
  .texted h3 {
      font-family: Poppins;
      font-size: 30px;
      font-weight: 700;
      line-height: 60px;
      text-align: left;
  }
  
  .texted p {
    width: 290px;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 400;
      line-height: 40px;
      text-align: justified;
  }

  @media screen and (min-width: 1339px) and (max-width: 1340px){
    .partner div.texted{
        margin-left: 4%;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1390px){
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 1.5%;
        display: flex;
        gap: 70px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 200px;
    }
    .texted .item-1 h3{
        font-size: 20px;
    }
    .texted .item-1 p{
        width: 200px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-2{
        width: 200px;
    }
    .texted .item-2 h3{
        font-size: 20px;
    }
    .texted .item-2 p{
        width: 200px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-3{
        width: 200px;
    }
    .texted .item-3 h3{
        font-size: 20px;
    }
    .texted .item-3 p{
        width: 200px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-4{
        width: 200px;
    }
    .texted .item-4 h3{
        font-size: 20px;
    }
    .texted .item-4 p{
        width: 200px;
        font-size: 15px;
        line-height: 28px;
    }
  }

@media screen and (min-width: 767px) and (max-width: 768px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner div.texted{
        margin-top: -3%;
        margin-left: 17%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}



@media screen and (min-width: 769px) and (max-width: 890px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}



@media screen and (min-width: 400px) and (max-width: 767px) {
    .partner-txt h1{
        font-size: 25px;
        letter-spacing: 1px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 6%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    .img-2{
        width: 150px;
        height: 150px;
    }
    .texted .item-1{
        width: 200px;
    }
    .texted .item-1 h3{
        width: 150px;
        font-size: 18px;
    }
    .texted .item-1 p{
        width: 170px;
        font-size: 10px;
        line-height: 25px;
    }
    .texted .item-2{
        margin-left: -5%;
        width: 200px;
    }
    .texted .item-2 h3{
        width: 170px;
        font-size: 18px;
    }
    .texted .item-2 p{
        width: 170px;
        font-size: 10px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 200px;
    }
    .texted .item-3 h3{
        width: 170px;
        font-size: 18px;
    }
    .texted .item-3 p{
        width: 170px;
        font-size: 10px;
        line-height: 25px;
    }
    .texted .item-4{
        margin-left: -5%;
        width: 200px;
    }
    .texted .item-4 h3{
        width: 170px;
        font-size: 18px;
    }
    .texted .item-4 p{
        width: 170px;
        font-size: 10px;
        line-height: 25px;
    }
}

@media screen and (max-width: 400px) {
    .partner{
        margin-top: 5%;
        width: 100%;
    }
    .partner-txt h1{
        font-size: 25px;
        letter-spacing: 1px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 15%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 170px;
    }
    .texted .item-1{
        width: 200px;
    }
    .texted .item-1 h3{
        width: 250px;
        font-size: 20px;
    }
    .texted .item-1 p{
        width: 250px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-2{
        width: 200px;
    }
    .texted .item-2 h3{
        width: 250px;
        font-size: 20px;
    }
    .texted .item-2 p{
        width: 250px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-3{
        width: 200px;
    }
    .texted .item-3 h3{
        width: 250px;
        font-size: 20px;
    }
    .texted .item-3 p{
        width: 250px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-4{
        width: 200px;
    }
    .texted .item-4 h3{
        width: 250px;
        font-size: 20px;
    }
    .texted .item-4 p{
        width: 250px;
        font-size: 15px;
        line-height: 28px;
    }
}

@media screen and (min-width: 310px) and (max-width: 320px){
    .partner .texted{
        margin-left: 10%;
    }
}


@media screen and (min-width: 981px) and (max-width: 1023px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 15%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 4%;
        display: flex;
        gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .img-2{
        width: 200px;
        height: 200px;
    }
    .texted .item-1{
        width: 180px;
    }
    .texted .item-1 h3{
        font-size: 20px;
    }
    .texted .item-1 p{
        width: 180px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-2{
        width: 180px;
    }
    .texted .item-2 h3{
        font-size: 20px;
    }
    .texted .item-2 p{
        width: 180px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-3{
        width: 180px;
    }
    .texted .item-3 h3{
        font-size: 20px;
    }
    .texted .item-3 p{
        width: 180px;
        font-size: 15px;
        line-height: 28px;
    }
    .texted .item-4{
        width: 180px;
    }
    .texted .item-4 h3{
        font-size: 20px;
    }
    .texted .item-4 p{
        width: 180px;
        font-size: 15px;
        line-height: 28px;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 15%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}

@media screen and (min-width: 890px) and (max-width: 910px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 5%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 15%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}


@media screen and (min-width: 970px) and (max-width: 980px) {
    .partner-txt h1{
        font-size: 40px;
    }
    .partner .texted{
        margin-top: -3%;
        margin-left: 15%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .img-2{
        width: 250px;
        height: 250px;
    }
    .texted .item-1{
        width: 250px;
    }
    .texted .item-1 h3{
        font-size: 25px;
    }
    .texted .item-1 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-2{
        width: 250px;
    }
    .texted .item-2 h3{
        font-size: 25px;
    }
    .texted .item-2 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-3{
        width: 250px;
    }
    .texted .item-3 h3{
        font-size: 25px;
    }
    .texted .item-3 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
    .texted .item-4{
        width: 250px;
    }
    .texted .item-4 h3{
        font-size: 25px;
    }
    .texted .item-4 p{
        width: 270px;
        font-size: 15px;
        line-height: 25px;
    }
}