@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700&display=swap');


.vision{
    width: 100%;
    margin-top: 1%;
}
.vision-txt h1{
    margin-top: -10%;
    font-family: Berkshire Swash;
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    color: white;
}
img.leaf{
    width: 200px;
    height: 200px;
    margin-left: 0%;
    margin-top: -1%;
}
.border{
    display: inline-block;
    padding: 10px;
    background-color: white;
    border: 5px;
}
.left-img{
    display: flex;
    margin-top: 5%;
}
.left-img .img-1{
    width: 650px;
    height: 810px;
    top: 1235px;
    left: 44px;
}
.right-side p{
    margin-left: 4%;
    margin-right: 5%;
    font-family: Poppins;
    font-size: 35px;
    font-weight: 400;
    line-height: 65px;
    text-align: justified;
    color: white;
}
.right-side .img-2{
    margin-top: 1%;
    margin-left: 0%;
    width: 850px;
    height: 380px;
}

@media screen and (min-width: 1024px) and (max-width: 1440px){
    .right-side .img-2{
        width: 770px;
        height: 315px;
    }
}

@media screen and (max-width: 768px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    .left-img img.img-1{
        width: 400px;
        height: 530px;
    }
    .right-side p{
        margin-left: 8%;
        width: 250px;
        font-size: 15px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 345px;
        height: 185px;
    }
}

@media screen and (min-width: 410px) and (max-width: 414px){
    div.right-side img.img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 207px;
        height: 125px;
    }
}

@media screen and (min-width: 400px) and (max-width: 767px){
    .vision-txt h1{
        margin-top: -15%;
        font-size: 25px;
        letter-spacing: 1px;
    }
    img.leaf{
        width: 70px;
        height: 70px;
        margin-top: -1%;
        margin-left: -1%;
    }
    .border{
        padding: 3px;
    }
    .left-img img.img-1{
        width: 200px;
        height: 300px;
    }
    .right-side p{
        margin-top: 2%;
        margin-left: 8%;
        width: 180px;
        font-size: 8px;
        line-height: 24px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 207px;
        height: 125px;
    }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
    .vision-txt h1{
        margin-top: -15%;
        font-size: 25px;
        letter-spacing: 1px;
    }
    img.leaf{
        width: 70px;
        height: 70px;
        margin-top: -1%;
        margin-left: -1%;
    }
    .border{
        padding: 3px;
    }
    .left-img img.img-1{
        width: 250px;
        height: 300px;
    }
    .right-side p{
        margin-top: 2%;
        margin-left: 8%;
        width: 250px;
        font-size: 8px;
        line-height: 24px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 295px;
        height: 167px;
    }
}

@media screen and (min-width: 769px) and (max-width: 890px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    .left-img img.img-1{
        width: 400px;
        height: 530px;
    }
    .right-side p{
        margin-left: 8%;
        width: 250px;
        font-size: 15px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 345px;
        height: 185px;
    }
}

@media screen and (max-width: 400px) {
    .vision{
        width: 100%;
    }
    .vision-txt h1{
        font-size: 25px;
        letter-spacing: 1px;
    }
    img.leaf{
        width: 70px;
        height: 70px;
        margin-top: -2%;
        margin-left: -1%;
    }
    div.border{
        height: 257px;
        padding: 3px;
    }
    .left-img img.img-1{
        width: 180px;
        height: 250px;
    }
    div.right-side{
        width: 150px;
    }
    .right-side p{
        margin-top: 2%;
        margin-left: 8%;
        width: 160px;
        font-size: 10px;
        line-height: 24px;
    }
    .right-side .img-2{
        margin-top: 12%;
        margin-left: -124%;
        width: 375px;
        height: 180px;
    }
}

@media screen and (min-width: 310px) and (max-width: 320px){
    .left-img img.img-1{
        width: 150px;
        height: 250px;
    }
    div.right-side{
        width: 150px;
    }
    div.border{
        height: 257px;
        padding: 3px;
    }
    .right-side p{
        margin-top: -5%;
        margin-left: 8%;
        width: 150px;
        font-size: 9.5px;
        line-height: 24px;
    }
    .right-side .img-2{
        margin-top: 2%;
        margin-left: -103%;
        width: 315px;
        height: 180px;
    }
}

@media screen and (min-width: 359px) and (max-width: 360px){
    div.border{
        height: 257px;
        padding: 3px;
    }
    .right-side p{
        margin-top: -5%;
        margin-left: 8%;
        width: 150px;
        font-size: 9.5px;
        line-height: 24px;
    }
    .right-side .img-2{
        margin-top: 2%;
        margin-left: -125%;
        width: 360px;
        height: 180px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1390px){
    .right-side p{
        margin-left: 4%;
        font-size: 25px;
        line-height: 45px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 530px;
        height: 458px;
    }
}

@media screen and (min-width: 1390px) and (max-width: 1400px){
    .right-side p{
        margin-left: 4%;
        font-size: 25px;
        line-height: 45px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 720px;
        height: 500px;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1439px){
    .left-img img.img-1{
        height: 900px;
    }
    .right-side .img-2{
        width: 735px;
        height: 340px;
    }
}


@media screen and (min-width: 1250px) and (max-width: 1290px){
    .right-side .img-2{
        width: 590px;
        height: 450px;
    }
}
@media screen and (min-width: 1290px) and (max-width: 1300px){
    .right-side .img-2{
        width: 630px;
        height: 500px;
    }
}

@media screen and (min-width: 1339px) and (max-width: 1340px){
    .right-side .img-2{
        width: 670px;
        height: 500px;
    }
}

@media screen and (min-width: 981px) and (max-width: 1023px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    div.border{
        height: 535px;
        padding: 3px;
    }
    .left-img img.img-1{
        width: 500px;
        height: 530px;
    }
    .right-side p{
        margin-left: 4%;
        width: 430px;
        font-size: 22px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 475px;
        height: 200px;
    }
}




@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    .left-img img.img-1{
        width: 500px;
        height: 530px;
    }
    .right-side p{
        margin-left: 4%;
        width: 470px;
        font-size: 22px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 505px;
        height: 250px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    div.border{
        height: 535px;
        padding: 3px;
    }
    .left-img img.img-1{
        width: 500px;
        height: 530px;
    }
    .right-side p{
        margin-left: 4%;
        width: 430px;
        font-size: 22px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 475px;
        height: 200px;
    }
}



@media screen and (min-width: 890px) and (max-width: 910px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    div.border{
        height: 535px;
        padding: 3px;
    }
    .left-img img.img-1{
        width: 500px;
        height: 530px;
    }
    .right-side p{
        margin-left: 4%;
        width: 350px;
        font-size: 18px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 390px;
        height: 200px;
    }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    div.border{
        height: 535px;
        padding: 3px;
    }
    .left-img img.img-1{
        width: 500px;
        height: 530px;
    }
    .right-side p{
        margin-left: 4%;
        width: 350px;
        font-size: 18px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 390px;
        height: 200px;
    }
}


@media screen and (min-width: 970px) and (max-width: 980px) {
    .vision-txt h1{
        font-size: 40px;
    }
    img.leaf{
        width: 120px;
        height: 120px;
        margin-top: -1%;
        margin-left: -1%;
    }
    div.border{
        height: 535px;
        padding: 3px;
    }
    .left-img img.img-1{
        width: 500px;
        height: 530px;
    }
    .right-side p{
        margin-left: 4%;
        width: 430px;
        font-size: 22px;
        line-height: 35px;
    }
    .right-side .img-2{
        margin-top: 4%;
        margin-left: 0%;
        width: 475px;
        height: 200px;
    }
}