@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: rgba(1, 77, 78, 1);
  overflow-x: hidden;
}
/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #1eb2a6;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #1eb2a6;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;
}
button {
  padding: 17px 30px;
  background-color: #fff;
  color: #1eb2a6;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: #1eb2a6;
  color: #fff;
}