@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');



/*--------head------------*/
.head {
  background-color: white;
  padding: 20px 0;
  margin-left: -5%;
}
.head-logo{
  width: 130px;
  height: 130px;
  top: 10px;
  left: 20px;
  gap: 0px;
}
.logo{
  margin-top: 2%;
  margin-left: -20%;
}
.logo h1 {
  color: rgba(1, 77, 78, 1);
  font-family: Berkshire Swash;
  font-size: 55px;
  font-weight: 700;
  text-align: left;
}
.logo span{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: center;
  color: rgba(252, 128, 25, 1);
}
.head .icon {
  margin-left: 10px;
}
.App-1{
  display: flex;
}
.App-1 a{
  height: 30px;
}
button.swiggy-button, button.zomato-button {
  margin-left: 1%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  height: 50px;
}

.swiggy-button {
  background-color: #fc8019; /* Swiggy orange */
  color: white;
}

.zomato-button {
  background-color: #e23744; /* Zomato red */
  color: white;
  font-size: 30px;
}

.logo-1 {
  width: 150px;
  height: 35px;
  margin-right: 5px;
}
.order {
  background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
  padding: 30px 70px;
  color: #fff;
  border-radius: 10px;
  margin-top: -2.5%;
  margin-left: 77%;
  width: 310px;
  height: 40px;
}
.button-1{
  font-family: Montserrat;
  width: 400px;
  margin-left: -4%;
  font-size: 13px;
  margin-top: -5%;
}
/*--------head------------*/

/* Define fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to header list items */
header ul li {
  opacity: 0; /* Start invisible */
  animation: fadeIn 0.5s ease forwards; /* Animation to fade in */
}

/* Delay each item to create sequential effect */
header ul li:nth-child(1) {
  animation-delay: 0.2s;
}

header ul li:nth-child(2) {
  animation-delay: 0.4s;
}

header ul li:nth-child(3) {
  animation-delay: 0.6s;
}

header ul li:nth-child(4) {
  animation-delay: 0.8s;
}

header ul li:nth-child(5) {
  animation-delay: 1s;
}

header ul li:nth-child(6) {
  animation-delay: 1.2s;
}

header ul li:nth-child(7) {
  animation-delay: 1.4s;
}

/* Add more items if needed */


/*--------header------------*/
header {
  margin-top: 0.2%;
  width: 100%;
  background-color: white;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  margin-left: 3%;
  padding: 30px 20px;
}
header ul li a {
  color: rgba(27, 70, 70, 1);
  font-size: 18px;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #1eb2a6;
}
.start {
  background: rgba(26, 6, 64, 1);
  padding: 30px 70px;
  color: #fff;
  border-radius: 10px;
  margin-top: 0.5%;
  margin-right: 2.5%;
  width: 400px;
  height: 0px;
}
div.button{
  font-family: Montserrat;
  margin-top: -4%;
}
.toggle {
  display: none;
}

ul.mobile-nav li a{
  font-family: Berkshire Swash;
  font-size: 15px;
}




/*--------header------------*/
@media screen and (min-width: 400px) and (max-width:767px){
  .head-logo{
    width: 70px;
    height: 70px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    width: 230px;
    margin-top: -1%;
    margin-left: -15%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 28px;
    width: 100%;
    margin-top: 5%;
  }
  .logo span{
    font-size: 11px;
    line-height: 10px;
  }
  button.swiggy-button, button.zomato-button {
    display:none;
  }
  section.head .order {
    display: none;
  }
  .start {
    display: none;
  }
  .button{
    width: 300px;
    margin-top: -5%;
    margin-left: -25%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  button.toggle {
    display: block;
    color: #100606;
    font-size: 30px;
    position: absolute;
    right: 0%;
    top: -100px;
  }
  .mobile-nav {
    position: relative;
    margin-left: 0%;
    width: 100%;
    background-color: rgba(27, 70, 70, 1);
  }
  header nav.flexSB ul.mobile-nav li a{
    color: white;
  }
}

@media screen and (min-width: 1439px) and (max-width: 1440px){
  div.order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 84.5%;
    width: 200px;
    height: 40px;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1290px){
  section.head div.order {
    margin-left: 82.4%;
  }
}

@media screen and (min-width: 1290px) and (max-width: 1300px){
  section.head div.order {
    margin-left: 82.7%;
  }
}

@media screen and (min-width: 1390px) and (max-width: 1400px){
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -25%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  div.logo span{
    font-size: 16px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header nav.flexSB{
    margin-top: 0.1%;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header nav.flexSB ul.flexSB {
    margin-left: 2%;
    padding: 30px 20px;
  }
  header nav.flexSB ul.flexSB li a {
    font-family: montserrat;
    color: rgba(27, 70, 70, 1);
    font-size: 14px;
    font-weight: 500;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  div.order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 83.8%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  .start {
    width: 300px;
    clip-path: none;
  }
  div.button{
    font-size: 14px;
    width: 300px;
    margin-top: -5%;
    margin-left: -28%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1439px){
  header nav.flexSB ul.flexSB li a {
    font-size: 17px;
  }
  div.order {
    margin-left: 84%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1250px){
  section.head div.order {
    margin-left: 82%;
  }
}



@media screen and (min-width: 1200px) and (max-width: 1390px){
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -27%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  div.logo span{
    font-size: 16px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header nav.flexSB{
    margin-top: 0.1%;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header nav.flexSB ul.flexSB {
    margin-left: 2%;
    padding: 30px 20px;
  }
  header nav.flexSB ul.flexSB li a {
    font-family: montserrat;
    color: rgba(27, 70, 70, 1);
    font-size: 13px;
    font-weight: 500;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  div.order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 82.8%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  .start {
    width: 300px;
    clip-path: none;
  }
  div.button{
    font-size: 14px;
    width: 300px;
    margin-top: -5%;
    margin-left: -28%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}

@media screen and (min-width: 1339px) and (max-width: 1340px){
  div.order {
    margin-left: 83.2%;
  }
}


@media screen and (min-width: 769px){
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -37%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 20px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header nav.flexSB{
    margin-top: 0.1%;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  header ul li a {
    font-family: montserrat;
    color: rgba(27, 70, 70, 1);
    font-size: 18px;
    font-weight: 500;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 85%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  .start {
    width: 300px;
    clip-path: none;
  }
  div.button{
    font-size: 14px;
    width: 300px;
    margin-top: -5%;
    margin-left: -28%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}


@media screen and (min-width: 767px) and (max-width: 768px) {
  .head-logo{
    width: 80px;
    height: 80px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1.5%;
    margin-left: -17%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 33px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 12px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header ul li a {
    color: white;
    font-size: 18px;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -4%;
    margin-left: 72%;
    width: 200px;
    height: 40px;
  }
  .button-1{
    width: 180px;
    margin-left: -75%;
    font-size: 14px;
    margin-top: -12%;
  }
  .start {
    display: none;
  }
  .button{
    width: 300px;
    margin-top: -5%;
    margin-left: -25%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  button.toggle {
    display: block;
    color: #100606;
    font-size: 30px;
    position: absolute;
    right: 95;
    top: -100px;
  }
  .mobile-nav {
    position: relative;
    margin-left: 0%;
    width: 100%;
    background-color: rgba(27, 70, 70, 1);
  }
}

@media screen and (max-width: 400px) {
  .head {
    width: 105%;
  }
  .head-logo{
    width: 70px;
    height: 70px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    width: 280px;
    margin-top: -2%;
    margin-left: -4%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 25px;
    margin-left: 6%;
    width: 100%;
    margin-top: 5%;
  }
  .logo span{
    font-size: 10px;
    margin-left: 5%;
    line-height: 10px;
  }
  button.swiggy-button, button.zomato-button {
    display:none;
  }
  section.head .order {
    display: none;
  }
  .start {
    display: none;
  }
  .button{
    width: 300px;
    margin-top: -5%;
    margin-left: -25%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  header nav.flexSB button.toggle {
    display: block;
    color: #100606;
    font-size: 20px;
    position: absolute;
    margin-left: 74%;
    top: -90px;
  }
  .mobile-nav {
    position: relative;
    margin-left: 0%;
    width: 100%;
    background-color: rgba(27, 70, 70, 1);
  }
  header nav.flexSB ul.mobile-nav li a{
    color: white;
  }
}

@media screen and (min-width: 359px) and (max-width: 360px){
  .logo span{
    font-size: 8px;
  }
  button.toggle {
    width: 80px;
    margin-left: 60%;
  }
}


@media screen and (min-width: 310px) and (max-width: 320px){
  section.head div.container.flexSB div.logo h1 {
    font-size: 20px;
    margin-left: 7%;
    width: 100%;
    margin-top: 5%;
  }
  .logo span{
    font-size: 12px;
    margin-left: 7%;
    line-height: 15px;
  }
  header nav.flexSB button.toggle {
    display: block;
    color: #100606;
    font-size: 22px;
    position: absolute;
    margin-left: 69%;
    top: -90px;
  }
}

@media screen and (min-width: 500px) and (max-width: 650px) {
  .head-logo{
    width: 80px;
    height: 80px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1.5%;
    margin-left: -40%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 33px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 12px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header ul li a {
    color: white;
    font-size: 18px;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -4%;
    margin-left: 72%;
    width: 200px;
    height: 40px;
  }
  .button-1{
    width: 180px;
    margin-left: -75%;
    font-size: 14px;
    margin-top: -12%;
  }
  .start {
    display: none;
  }
  .button{
    width: 300px;
    margin-top: -5%;
    margin-left: -25%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  button.toggle {
    display: block;
    color: #100606;
    font-size: 30px;
    position: absolute;
    right: 95;
    top: -100px;
  }
  .mobile-nav {
    position: relative;
    margin-left: 0%;
    width: 100%;
    background-color: rgba(27, 70, 70, 1);
  }
}


@media screen and (min-width: 769px) and (max-width: 890px) {
  .head-logo{
    width: 80px;
    height: 80px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1.5%;
    margin-left: -17%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 33px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 12px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header ul li a {
    color: white;
    font-size: 18px;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -4%;
    margin-left: 72%;
    width: 200px;
    height: 40px;
  }
  .button-1{
    width: 180px;
    margin-left: -75%;
    font-size: 14px;
    margin-top: -12%;
  }
  .start {
    display: none;
  }
  .button{
    width: 300px;
    margin-top: -5%;
    margin-left: -25%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  button.toggle {
    display: block;
    color: #100606;
    font-size: 30px;
    position: absolute;
    right: 95;
    top: -100px;
  }
  .mobile-nav {
    position: relative;
    margin-left: 0%;
    width: 100%;
    background-color: rgba(27, 70, 70, 1);
  }
}




@media screen and (min-width: 981px) and (max-width: 1023px) {
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -10%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 20px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  ul.flexSB li {
    margin-left: -3%;
  }
  header ul li a {
    color: rgba(27, 70, 70, 1);
    font-size: 10px;
    font-weight: 700;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 77.5%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  div.start {
    width: 250px;
    margin-right: 2%;
    clip-path: none;
  }
  div.button{
    width: 250px;
    font-size: 12px;
    margin-top: -7%;
    margin-left: -49%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}


@media screen and (min-width: 1023px) and (max-width: 1024px) {
  .head-logo{
    width: 90px;
    height: 90px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -15%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 20px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  header nav.flexSB ul.flexSB li a{
    font-family: Montserrat;
    color: rgba(27, 70, 70, 1);
    font-size: 8px;
    font-weight: 600;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 78.5%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  .start {
    width: 300px;
    clip-path: none;
  }
  .button{
    width: 300px;
    margin-top: -5%;
    margin-left: -25%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -10%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 20px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  ul.flexSB li {
    margin-left: -3%;
  }
  header ul li a {
    color: rgba(27, 70, 70, 1);
    font-size: 10px;
    font-weight: 700;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 78.5%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  div.start {
    width: 250px;
    margin-right: 2%;
    clip-path: none;
  }
  div.button{
    width: 250px;
    font-size: 12px;
    margin-top: -7%;
    margin-left: -49%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}



@media screen and (min-width: 890px) and (max-width: 910px) {
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 2%;
    margin-left: -5%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 45px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 18px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  ul.flexSB li {
    margin-left: -3%;
  }
  header ul li a {
    color: rgba(27, 70, 70, 1);
    font-size: 8px;
    font-weight: 700;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 75.4%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  div.start {
    width: 250px;
    margin-right: 2%;
    clip-path: none;
  }
  div.button{
    width: 250px;
    font-size: 12px;
    margin-top: -7%;
    margin-left: -49%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}

@media screen and (min-width: 910px) and (max-width: 970px) {
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 2%;
    margin-left: -5%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 45px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 18px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  ul.flexSB li {
    margin-left: -3%;
  }
  header ul li a {
    color: rgba(27, 70, 70, 1);
    font-size: 8px;
    font-weight: 700;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 75.4%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  div.start {
    width: 250px;
    margin-right: 2%;
    clip-path: none;
  }
  div.button{
    width: 250px;
    font-size: 12px;
    margin-top: -7%;
    margin-left: -49%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}




@media screen and (min-width: 970px) and (max-width: 980px) {
  .head-logo{
    width: 100px;
    height: 100px;
  }
  section.head div.container.flexSB{
    height: 100px;
  }
  section.head div.container.flexSB div.logo{
    margin-top: 1%;
    margin-left: -10%;
  }
  section.head div.container.flexSB div.logo h1 {
    font-size: 50px;
    width: 100%;
    margin-top: -1%;
  }
  .logo span{
    font-size: 20px;
  }
  .swiggy-button, .zomato-button {
    margin-left: 15%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100px;
    height: 50px;
  }
  
  .swiggy-button {
    background-color: #fc8019; /* Swiggy orange */
    color: white;
  }
  
  .zomato-button {
    background-color: #e23744; /* Zomato red */
    color: white;
  }
  
  .logo-1 {
    height: 20px;
    margin-right: 15px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    margin-left: 3%;
    padding: 30px 20px;
  }
  ul.flexSB li {
    margin-left: -3%;
  }
  header ul li a {
    color: rgba(27, 70, 70, 1);
    font-size: 10px;
    font-weight: 700;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .logo{
    margin-left: 0%;
  }
  .App-1{
    margin-top: -3%;
    margin-right: -7%;
  }
  .order {
    background: linear-gradient(90deg, #1B4E50 0%, #3DB1B6 100%);
    padding: 30px 70px;
    color: #fff;
    border-radius: 10px;
    margin-top: -3.5%;
    margin-left: 77.5%;
    width: 200px;
    height: 40px;
  }
  section.head div.order div.button-1{
    width: 150px;
    margin-left: -75%;
    font-size: 13px;
    margin-top: -12%;
  }
  div.start {
    width: 250px;
    margin-right: 2%;
    clip-path: none;
  }
  div.button{
    width: 250px;
    font-size: 12px;
    margin-top: -7%;
    margin-left: -49%;
  }
  header {
    margin: 0;
    background-color: white;
    position: relative;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: none;
  }
}
